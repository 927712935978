import React, { useState, useEffect } from "react";
import "../index.scss";
import { useSelector } from "react-redux";
import { Divider, TreeSelect } from "antd";
import { sortUserFunc } from "utils/commonUtils";

const { SHOW_PARENT } = TreeSelect;

const UserTree = (props) => {
  const {
    filterDataPass,
    userNodes,
    isReload,
    singleSelect,
    selectedUserIds,
    singleSelectReload,
    userSelectWidth,
  } = props || {};

  const { user_list_failed, user_list_processing, user_map_data } = useSelector(
    (state) => state.users
  );

  const [allSelect, setAllSelect] = useState(false);

  const handleClear = () => {
    setAllSelect(false);
    filterDataPass([]);
  };

  const handleaAllSelect = () => {
    setAllSelect(true);
    filterDataPass([]);
  };

  useEffect(() => {
    if (isReload) {
      setAllSelect(false);
    }
  }, [isReload]);

  useEffect(() => {
    if (user_list_failed === true) {
      setAllSelect(false);
      filterDataPass([]);
    }
  }, [user_list_failed]);

  const onChange = (newValue) => {
    if (newValue !== undefined) {
      filterDataPass(singleSelect ? [newValue] : newValue);
    }
    setAllSelect(false);
  };

  const dropdownRender = (menus) => (
    <div>
      {!singleSelect && (
        <>
          <div className="d_filter">
            <div className="d_hover" onClick={handleaAllSelect}>
              {" "}
              Select all
            </div>
            <div className="d_hover" onClick={handleClear}>
              Clear
            </div>
          </div>

          <Divider
            style={{
              margin: "0px",
              marginBottom: "5px",
            }}
          />
        </>
      )}
      {menus}
    </div>
  );

  const sortUsers = (userA, userB) => {
    return selectedUserIds.includes(userA.value) &&
      selectedUserIds.includes(userB.value)
      ? 0
      : selectedUserIds.includes(userA.value)
      ? -1
      : selectedUserIds.includes(userB.value)
      ? 1
      : sortUserFunc(
          user_map_data[userA.value]?.identity || {},
          user_map_data[userB.value]?.identity || {}
        );
  };

  return (
    <TreeSelect
      dropdownMatchSelectWidth={false}
      autoClearSearchValue={false}
      loading={user_list_processing}
      treeData={[...userNodes].sort(sortUsers)}
      value={
        allSelect
          ? userNodes.map((userNode) => userNode.value)
          : selectedUserIds
      }
      onChange={onChange}
      allowClear={singleSelectReload && singleSelect}
      treeCheckable={!singleSelect}
      showCheckedStrategy={SHOW_PARENT}
      placeholder={
        singleSelectReload ? "Select User" : singleSelect ? "" : "All Users"
      }
      showSearch={true}
      showArrow={true}
      maxTagCount="responsive"
      style={{
        minWidth: userSelectWidth,
      }}
      onClear={handleClear}
      dropdownRender={dropdownRender}
      filterTreeNode={(input, option) =>
        // @ts-ignore
        (option.title || "").toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    />
  );
};

export default UserTree;
