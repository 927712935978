import { sortUserFunc } from "utils/commonUtils";
import * as actionTypes from "./actionTypes";
import {
  TEAM_MEMBER_UPDATE_SUCCESS,
  UPDATE_USER_SETTING_SUCCESS,
} from "../team/actionType";

const getUserListData = (userMap) =>
  Object.values(userMap)
    .filter(
      (user) => user?.identity?.is_active && !user?.identity?.non_tracking
    )
    .map((user) => ({
      ...user,
      identity: { ...user.identity, employee_id: user.employee_id },
    }))
    .sort((userA, userB) => sortUserFunc(userA?.identity, userB?.identity));

const getAllActiveUserListData = (userMap) =>
  Object.values(userMap)
    .filter((user) => user?.identity?.is_active)
    .map((user) => ({
      ...user,
      identity: { ...user.identity, employee_id: user.employee_id },
    }))
    .sort((userA, userB) => sortUserFunc(userA?.identity, userB?.identity));

const meDetailsUpdate = (updatedUserInfo, medetails_data) =>
  updatedUserInfo?.identity?.id === medetails_data?.identity?.id
    ? {
        medetails_data: {
          ...updatedUserInfo,
          identity: {
            ...updatedUserInfo.identity,
            employee_id: updatedUserInfo.employee_id,
          },
        },
      }
    : {};

const initialState = {
  user_create_processing: false,
  user_create_success: false,
  user_create_failed: false,
  user_create_data: [],
  user_create_message: "",

  user_invite_create_processing: false,
  user_invite_create_success: false,
  user_invite_create_failed: false,
  user_invite_create_data: [],
  user_invite_create_message: "",

  user_list_processing: false,
  user_list_success: false,
  user_list_failed: false,
  user_list_data: [],
  all_active_user_list_data: [],
  user_map_data: {},
  user_list_message: "",

  field_user_list_processing: false,
  field_user_list_success: false,
  field_user_list_failed: false,
  field_user_list_data: [],
  field_user_pagination: "",

  team_wise_user_list_processing: false,
  team_wise_user_list_success: false,
  team_wise_user_list_failed: false,
  team_wise_user_list_data: [],
  team_wise_user_list_message: "",

  role_user_list_processing: false,
  role_user_list_success: false,
  role_user_list_failed: false,
  role_user_list_data: [],
  role_user_list_message: "",
  roleUserMaster: [],

  user_update_processing: false,
  user_update_success: false,
  user_update_failed: false,
  user_update_data: [],
  user_update_message: "",

  user_info_update_processing: false,
  user_info_update_success: false,
  user_info_update_failed: false,
  user_info_update_data: [],
  user_info_update_message: "",

  role_user_update_processing: false,
  role_user_update_success: false,
  role_user_update_failed: false,
  role_user_update_data: [],
  role_user_update_message: "",

  //* Me DETAILS *//

  medetails_processing: false,
  medetails_success: false,
  medetails_failed: false,
  medetails_data: {},
  medetails_error_message: "",

  medetails_recheck_processing: false,
  medetails_recheck_success: false,
  medetails_recheck_failed: false,
  medetails_recheck_data: {},
  medetails_recheck_error_message: "",
  me_role_loading: false,
  me_role_success: false,
  me_role_failed: false,
  me_role_data: {},
  me_role_error_message: "",

  upload_avatar_processing: false,
  upload_avatar_success: false,
  upload_avatar_failed: false,
  upload_avatar_data: {},
  upload_avatar_message: "",

  get_user_ids_processing: false,
  get_user_ids_success: false,
  get_user_ids_failed: false,
  get_user_ids_data: {},
  get_user_ids_message: "",

  field_user_by_id_processing: false,
  field_user_by_id_success: false,
  field_user_by_id_failed: false,
  field_user_by_id_data: [],

  field_user_last_location_processing: false,
  field_user_last_location_success: false,
  field_user_last_location_failed: false,
  field_user_last_location_data: [],

  user_bulk_import_validate_processing: false,
  user_bulk_import_validate_success: false,
  user_bulk_import_validate_failed: false,
  user_bulk_import_validate_error_data: {},

  user_bulk_import_processing: false,
  user_bulk_import_success: false,
  user_bulk_import_data: false,
  user_bulk_import_failed: false,
  user_bulk_import_message: {},

  user_bulk_import_get_job_status_processing: false,
  user_bulk_import_get_job_status_success: false,
  user_bulk_import_get_job_status_data: false,
  user_bulk_import_get_job_status_failed: false,
  user_bulk_import_get_job_status_message: {},

  user_bulk_import_logs_processing: false,
  user_bulk_import_logs_success: false,
  user_bulk_import_logs_data: false,
  user_bulk_importlogs_failed: false,
  user_bulk_import_logs_message: {},
  user_new_list_processing: false,
  user_new_list_success: false,
  user_new_list_failed: false,
  user_new_list_data: [],

  user_search_list_processing: false,
  user_search_list_success: false,
  user_search_list_failed: false,
  user_search_list_data: { data: [], meta: {} },
  user_search_list_message: "",

  deactivateUsersLoading: false,
  activateUsersLoading: false,
  deactivateUsersSuccess: false,
  activateUsersSuccess: false,

  export_user_list_processing: false,
  export_user_list_success: false,
  export_user_list_failed: false,
  export_user_list_error_msg: "",

  userTeamUpdateLoading: true,
  userTeamUpdateErrorMessage: "",
  userTeamUpdateSuccess: false,
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.USER_CREATE_INITIAL:
      return {
        ...state,
        user_create_processing: false,
        user_create_success: false,
        user_create_failed: false,
        user_create_data: [],
        user_create_message: "",
      };
    case actionTypes.USER_CREATE_PROCESS:
      return {
        ...state,
        user_create_processing: true,
        user_create_success: false,
        user_create_failed: false,
        user_create_data: [],
        user_create_message: "",
      };

    case actionTypes.USER_CREATE_SUCCESS: {
      const userMapData = {
        ...state.user_map_data,
        [action.data.identity.id]: {
          ...action.data?.identity,
          ...action.data,
        },
      };

      return {
        ...state,
        user_create_processing: false,
        user_create_success: true,
        user_create_failed: false,
        user_create_data: action.data,
        all_active_user_list_data: getAllActiveUserListData(userMapData),
        user_list_data: getUserListData(userMapData),
        user_map_data: userMapData,
        user_create_message: "",
      };
    }

    case actionTypes.USER_CREATE_FAILED:
      return {
        ...state,
        user_create_processing: false,
        user_create_success: false,
        user_create_failed: true,
        user_create_data: [],
        user_create_message: action.data,
      };

    case actionTypes.USER_INVITE_CREATE_INITIAL:
      return {
        ...state,
        user_invite_create_processing: false,
        user_invite_create_success: false,
        user_invite_create_failed: false,
        user_invite_create_data: [],
        user_invite_create_message: "",
      };
    case actionTypes.USER_INVITE_CREATE_PROCESS:
      return {
        ...state,
        user_invite_create_processing: true,
        user_invite_create_success: false,
        user_invite_create_failed: false,
        user_invite_create_data: [],
        user_invite_create_message: "",
      };

    case actionTypes.USER_INVITE_CREATE_SUCCESS:
      return {
        ...state,
        user_invite_create_processing: false,
        user_invite_create_success: true,
        user_invite_create_failed: false,
        user_invite_create_data: action.data,
        user_invite_create_message: "",
      };
    case actionTypes.USER_INVITE_CREATE_FAILED:
      return {
        ...state,
        user_invite_create_processing: false,
        user_invite_create_success: false,
        user_invite_create_failed: true,
        user_invite_create_data: [],
        user_invite_create_message: action.data,
      };

    case actionTypes.USER_LIST_INITIAL:
      return {
        ...state,
        user_list_processing: false,
        user_list_success: false,
        user_list_failed: false,
        user_list_message: "",
      };
    case actionTypes.USER_LIST_PROCESS:
      return {
        ...state,
        user_list_processing: true,
        user_list_success: false,
        user_list_failed: false,
        user_list_data: [],
        user_list_message: "",
      };

    case actionTypes.USER_LIST_SUCCESS: {
      const userMapData = action.data.data.reduce(
        (acc, user) => ({
          ...acc,
          [user?.identity.id]: {
            ...user?.identity,
            ...user,
          },
        }),
        { ...state.user_map_data }
      );
      return {
        ...state,
        user_list_processing: false,
        user_list_success: true,
        user_list_failed: false,
        all_active_user_list_data: getAllActiveUserListData(userMapData),
        user_list_data: getUserListData(userMapData),
        user_map_data: userMapData,
        user_list_message: "",
      };
    }

    case actionTypes.USER_LIST_FAILED:
      return {
        ...state,
        user_list_processing: false,
        user_list_success: false,
        user_list_failed: true,
        all_active_user_list_data: [],
        user_list_data: [],
        user_map_data: {},
        user_list_message: action.data,
      };

    case UPDATE_USER_SETTING_SUCCESS:
    case TEAM_MEMBER_UPDATE_SUCCESS: {
      const userMapDataUpdated = {
        ...state.user_map_data,
        ...(action.data?.data?.identity?.id
          ? {
              [action.data.data.identity.id]: {
                ...action.data.data?.identity,
                ...action.data.data,
              },
            }
          : {}),
      };
      return {
        ...state,
        user_map_data: userMapDataUpdated,
        user_list_data: getUserListData(userMapDataUpdated),
        all_active_user_list_data: getAllActiveUserListData(userMapDataUpdated),
        ...meDetailsUpdate(action?.data?.data, state.medetails_data),
      };
    }

    case actionTypes.FIELD_USER_LIST_INITIAL:
      return {
        ...state,
        field_user_list_processing: false,
        field_user_list_success: false,
        field_user_list_failed: false,
        field_user_list_message: "",
      };
    case actionTypes.FIELD_USER_LIST_PROCESS:
      return {
        ...state,
        field_user_list_processing: true,
        field_user_list_success: false,
        field_user_list_failed: false,
        field_user_list_data: [],
        field_user_list_message: "",
      };

    case actionTypes.FIELD_USER_LIST_SUCCESS: {
      return {
        ...state,
        field_user_list_processing: false,
        field_user_list_success: true,
        field_user_list_failed: false,
        field_user_list_data: action.data.data || [],
        field_user_list_message: "",
        field_user_pagination: action.data.meta || state.field_user_pagination,
      };
    }
    case actionTypes.FIELD_USER_LIST_FAILED:
      return {
        ...state,
        field_user_list_processing: false,
        field_user_list_success: false,
        field_user_list_failed: true,
        field_user_list_data: [],
        field_user_list_message: action.data,
      };

    case actionTypes.USER_UPDATE_INITIAL:
      return {
        ...state,
        user_update_processing: false,
        user_update_success: false,
        user_update_failed: false,
        user_update_data: [],
        user_update_message: "",
      };
    case actionTypes.USER_UPDATE_PROCESS:
      return {
        ...state,
        user_update_processing: true,
        user_update_success: false,
        user_update_failed: false,
        user_update_data: [],
        user_update_message: "",
      };

    case actionTypes.USER_UPDATE_SUCCESS:
      return {
        ...state,
        user_update_processing: false,
        user_update_success: true,
        user_update_failed: false,
        user_update_data: action.data,
        user_update_message: "",
      };
    case actionTypes.USER_UPDATE_FAILED:
      return {
        ...state,
        user_update_processing: false,
        user_update_success: false,
        user_update_failed: true,
        user_update_data: [],
        user_update_message: action.data,
      };

    case actionTypes.ROLE_USER_UPDATE_INITIAL:
      return {
        ...state,
        role_user_update_processing: false,
        role_user_update_success: false,
        role_user_update_failed: false,
        role_user_update_data: [],
        role_user_update_message: "",
      };
    case actionTypes.ROLE_USER_UPDATE_PROCESS:
      return {
        ...state,
        role_user_update_processing: true,
        role_user_update_success: false,
        role_user_update_failed: false,
        role_user_update_data: [],
        role_user_update_message: "",
      };

    case actionTypes.ROLE_USER_UPDATE_SUCCESS:
      return {
        ...state,
        role_user_update_processing: false,
        role_user_update_success: true,
        role_user_update_failed: false,
        role_user_update_data: action.data,
        role_user_update_message: "",
      };
    case actionTypes.ROLE_USER_UPDATE_FAILED:
      return {
        ...state,
        role_user_update_processing: false,
        role_user_update_success: false,
        role_user_update_failed: true,
        role_user_update_data: [],
        role_user_update_message: action.data,
      };

    case actionTypes.USER_INFO_UPDATE_INITIAL:
      return {
        ...state,
        user_info_update_processing: false,
        user_info_update_success: false,
        user_info_update_failed: false,
        user_info_update_data: [],
        user_info_update_message: "",
      };
    case actionTypes.USER_INFO_UPDATE_PROCESS:
      return {
        ...state,
        user_info_update_processing: true,
        user_info_update_success: false,
        user_info_update_failed: false,
        user_info_update_data: [],
        user_info_update_message: "",
      };

    case actionTypes.USER_INFO_UPDATE_SUCCESS: {
      const userMapDataUpdated = {
        ...state.user_map_data,
        ...(action.data?.identity?.id
          ? {
              [action.data.identity.id]: {
                ...action.data?.identity,
                ...action.data,
              },
            }
          : {}),
      };
      return {
        ...state,
        user_map_data: userMapDataUpdated,
        user_list_data: getUserListData(userMapDataUpdated),
        all_active_user_list_data: getAllActiveUserListData(userMapDataUpdated),
        ...meDetailsUpdate(action?.data, state.medetails_data),
        user_info_update_processing: false,
        user_info_update_success: true,
        user_info_update_failed: false,
        user_info_update_data: action.data,
        user_info_update_message: "",
      };
    }

    case actionTypes.USER_INFO_UPDATE_FAILED:
      return {
        ...state,
        user_info_update_processing: false,
        user_info_update_success: false,
        user_info_update_failed: true,
        user_update_data: [],
        user_info_update_message: action.data,
      };

    case actionTypes.USER_LIST_SEARCH_INITIAL:
      return {
        ...state,
        user_list_search: false,
      };

    case actionTypes.ROLE_USER_LIST_INITIAL:
      return {
        ...state,
        role_user_list_processing: false,
        role_user_list_success: false,
        role_user_list_failed: false,
        role_user_list_message: "",
      };
    case actionTypes.ROLE_USER_LIST_PROCESS:
      return {
        ...state,
        role_user_list_processing: true,
        role_user_list_success: false,
        role_user_list_failed: false,
        role_user_list_data: [],
        roleUserMaster: [],
        role_user_list_message: "",
      };

    case actionTypes.ROLE_USER_LIST_SUCCESS:
      return {
        ...state,
        role_user_list_processing: false,
        role_user_list_success: true,
        role_user_list_failed: false,
        role_user_list_data: action.data.data,
        roleUserMaster: action.data.data,
        role_user_list_message: "",
      };
    case actionTypes.ROLE_USER_LIST_FAILED: {
      return {
        ...state,
        role_user_list_processing: false,
        role_user_list_success: false,
        role_user_list_failed: true,
        role_user_list_data: [],
        roleUserMaster: [],
        role_user_list_message: action.data,
      };
    }

    case actionTypes.ME_DETAILS_INITIAL:
      return {
        ...state,
        medetails_processing: false,
        medetails_success: false,
        medetails_failed: false,
        medetails_data: {},
        medetails_error_message: "",
      };
    case actionTypes.ME_DETAILS_PROCESS:
      return {
        ...state,
        medetails_loading: true,
        medetails_success: false,
        medetails_failed: false,
        medetails_data: {},
      };
    case actionTypes.ME_DETAILS_SUCCESS: {
      const meDetailsData = {
        ...action.data,
        identity: {
          ...action.data.identity,
          employee_id: action.data.employee_id,
        },
        attached_group_id:
          action.data.attached_group_id || action.data.attached_group?.id,
      };

      delete meDetailsData.attached_group;

      return {
        ...state,
        medetails_loading: false,
        medetails_success: true,
        medetails_failed: false,
        medetails_data: meDetailsData,
      };
    }

    case actionTypes.ME_DETAILS_FAILED:
      return {
        ...state,
        medetails_loading: false,
        medetails_success: false,
        medetails_failed: true,
        medetails_error_message: action.data,
        medetails_data: {},
      };

    case actionTypes.ME_DETAILS_RECHECK_INITIAL:
      return {
        ...state,
        medetails_recheck_processing: false,
        medetails_recheck_success: false,
        medetails_recheck_failed: false,
        medetails_recheck_data: {},
        medetails_recheck_error_message: "",
      };
    case actionTypes.ME_DETAILS_RECHECK_PROCESS:
      return {
        ...state,
        medetails_recheck_processing: true,
        medetails_recheck_success: false,
        medetails_recheck_failed: false,
        medetails_recheck_data: {},
      };
    case actionTypes.ME_DETAILS_RECHECK_SUCCESS:
      return {
        ...state,
        medetails_recheck_processing: false,
        medetails_recheck_success: true,
        medetails_recheck_failed: false,
        medetails_recheck_data: action.data,
      };
    case actionTypes.ME_DETAILS_RECHECK_FAILED:
      return {
        ...state,
        medetails_recheck_processing: false,
        medetails_recheck_success: false,
        medetails_recheck_failed: true,
        medetails_recheck_error_message: action.data,
        medetails_recheck_data: {},
      };

    case actionTypes.TEAM_WISE_USER_LIST_INITIAL:
      return {
        ...state,
        team_wise_user_list_processing: false,
        team_wise_user_list_success: false,
        team_wise_user_list_failed: false,
        team_wise_user_list_data: [],
        team_wise_user_list_message: "",
      };
    case actionTypes.TEAM_WISE_USER_LIST_PROCESS:
      return {
        ...state,
        team_wise_user_list_processing: true,
        team_wise_user_list_success: false,
        team_wise_user_list_failed: false,
        team_wise_user_list_data: [],
        team_wise_user_list_message: "",
      };

    case actionTypes.TEAM_WISE_USER_LIST_SUCCESS:
      return {
        ...state,
        team_wise_user_list_processing: false,
        team_wise_user_list_success: true,
        team_wise_user_list_failed: false,
        team_wise_user_list_data: action.data,
        team_wise_user_list_message: "",
      };
    case actionTypes.TEAM_WISE_USER_LIST_FAILED:
      return {
        ...state,
        team_wise_user_list_processing: false,
        team_wise_user_list_success: false,
        team_wise_user_list_failed: true,
        team_wise_user_list_data: [],
        team_wise_user_list_message: action.data,
      };

    case actionTypes.SET_UPLOAD_AVATAR_INITIAL:
      return {
        ...state,
        upload_avatar_processing: false,
        upload_avatar_success: false,
        upload_avatar_failed: false,
        upload_avatar_data: [],
        upload_avatar_message: "",
      };
    case actionTypes.SET_UPLOAD_AVATAR_PROCESS:
      return {
        ...state,
        upload_avatar_processing: true,
        upload_avatar_success: false,
        upload_avatar_failed: false,
        upload_avatar_data: [],
        upload_avatar_message: "",
      };

    case actionTypes.SET_UPLOAD_AVATAR_SUCCESS:
      return {
        ...state,
        upload_avatar_processing: false,
        upload_avatar_success: true,
        upload_avatar_failed: false,
        upload_avatar_data: action.data,
        upload_avatar_message: "",
      };
    case actionTypes.SET_UPLOAD_AVATAR_FAILED:
      return {
        ...state,
        upload_avatar_processing: false,
        upload_avatar_success: false,
        upload_avatar_failed: true,
        upload_avatar_data: [],
        upload_avatar_message: action.data,
      };

    case actionTypes.GET_USER_IDS_INITIAL:
      return {
        ...state,
        get_user_ids_processing: false,
        get_user_ids_success: false,
        get_user_ids_failed: false,
        get_user_ids_data: {},
        get_user_ids_message: "",
      };
    case actionTypes.GET_USER_IDS_PROCESS:
      return {
        ...state,
        get_user_ids_processing: true,
        get_user_ids_success: false,
        get_user_ids_failed: false,
        get_user_ids_data: {},
        get_user_ids_message: "",
      };

    case actionTypes.GET_USER_IDS_SUCCESS:
      return {
        ...state,
        get_user_ids_processing: false,
        get_user_ids_success: true,
        get_user_ids_failed: false,
        get_user_ids_data: action.data,
        get_user_ids_message: "",
      };
    case actionTypes.GET_USER_IDS_FAILED:
      return {
        ...state,
        get_user_ids_processing: false,
        get_user_ids_success: false,
        get_user_ids_failed: true,
        get_user_ids_data: {},
        get_user_ids_message: action.data,
      };

    case actionTypes.FIELD_USER_BY_ID_INITIAL:
      return {
        ...state,
        field_user_by_id_processing: false,
        field_user_by_id_success: false,
        field_user_by_id_failed: false,
        field_user_by_id_message: "",
      };
    case actionTypes.FIELD_USER_BY_ID_PROCESS:
      return {
        ...state,
        field_user_by_id_processing: true,
        field_user_by_id_success: false,
        field_user_by_id_failed: false,
        field_user_by_id_data: [],
        field_user_by_id_message: "",
      };

    case actionTypes.FIELD_USER_BY_ID_SUCCESS: {
      return {
        ...state,
        field_user_by_id_processing: false,
        field_user_by_id_success: true,
        field_user_by_id_failed: false,
        field_user_by_id_data: action.data,
        field_user_by_id_message: "",
      };
    }
    case actionTypes.FIELD_USER_BY_ID_FAILED:
      return {
        ...state,
        field_user_by_id_processing: false,
        field_user_by_id_success: false,
        field_user_by_id_failed: true,
        field_user_by_id_data: [],
        field_user_by_id_message: action.data,
      };

    case actionTypes.FIELD_USER_LAST_LOCATION_INITIAL:
      return {
        ...state,
        field_user_last_location_processing: false,
        field_user_last_location_success: false,
        field_user_last_location_failed: false,
        field_user_last_location_message: "",
      };
    case actionTypes.FIELD_USER_LAST_LOCATION_PROCESS:
      return {
        ...state,
        field_user_last_location_processing: true,
        field_user_last_location_success: false,
        field_user_last_location_failed: false,
        field_user_last_location_data: [],
        field_user_last_location_message: "",
      };

    case actionTypes.FIELD_USER_LAST_LOCATION_SUCCESS: {
      return {
        ...state,
        field_user_last_location_processing: false,
        field_user_last_location_success: true,
        field_user_last_location_failed: false,
        field_user_last_location_data: action.data,
        field_user_last_location_message: "",
      };
    }
    case actionTypes.FIELD_USER_LAST_LOCATION_FAILED:
      return {
        ...state,
        field_user_last_location_processing: false,
        field_user_last_location_success: false,
        field_user_last_location_failed: true,
        field_user_last_location_data: [],
        field_user_last_location_message: action.data,
      };

    // user bulk import validate
    case actionTypes.USER_BULK_IMPORT_VALIDATE_INITIAL:
      return {
        ...state,

        user_bulk_import_validate_processing: false,
        user_bulk_import_validate_success: false,
        user_bulk_import_validate_failed: false,
        user_bulk_import_validate_error_data: "",
      };
    case actionTypes.USER_BULK_IMPORT_VALIDATE_PROCESS:
      return {
        ...state,
        user_bulk_import_validate_processing: true,
        user_bulk_import_validate_success: false,
        user_bulk_import_validate_failed: false,
        user_bulk_import_validate_error_data: "",
      };

    case actionTypes.USER_BULK_IMPORT_VALIDATE_SUCCESS: {
      return {
        ...state,
        user_bulk_import_validate_processing: false,
        user_bulk_import_validate_success: true,
        user_bulk_import_validate_failed: false,
        user_bulk_import_validate_error_data: "",
      };
    }
    case actionTypes.USER_BULK_IMPORT_VALIDATE_FAILED:
      return {
        ...state,
        user_bulk_import_validate_processing: false,
        user_bulk_import_validate_success: false,
        user_bulk_import_validate_failed: true,
        user_bulk_import_validate_error_data: action.data,
      };

    // user bulk import
    case actionTypes.USER_BULK_IMPORT_INITIAL:
      return {
        ...state,

        user_bulk_import_processing: false,
        user_bulk_import_success: false,
        user_bulk_import_failed: false,
        user_bulk_import_data: [],
        user_bulk_import_message: "",
      };
    case actionTypes.USER_BULK_IMPORT_PROCESS:
      return {
        ...state,
        user_bulk_import_processing: true,
        user_bulk_import_success: false,
        user_bulk_import_failed: false,
        user_bulk_import_data: [],
        user_bulk_import_message: "",
      };

    case actionTypes.USER_BULK_IMPORT_SUCCESS: {
      return {
        ...state,
        user_bulk_import_processing: false,
        user_bulk_import_success: true,
        user_bulk_import_failed: false,
        user_bulk_import_data: action.data,
        user_bulk_import_message: "",
      };
    }
    case actionTypes.USER_BULK_IMPORT_FAILED:
      return {
        ...state,
        user_bulk_import_processing: false,
        user_bulk_import_success: false,
        user_bulk_import_failed: true,
        user_bulk_import_data: [],
        user_bulk_import_message: action.data,
      };

    // user bulk import get job status
    case actionTypes.USER_BULK_IMPORT_GET_JOB_STATUS_INITIAL:
      return {
        ...state,

        user_bulk_import_get_job_status_processing: false,
        user_bulk_import_get_job_status_success: false,
        user_bulk_import_get_job_status_data: [],
        user_bulk_import_get_job_status_failed: false,
        user_bulk_import_get_job_status_message: {},
      };
    case actionTypes.USER_BULK_IMPORT_GET_JOB_STATUS_PROCESS:
      return {
        ...state,
        user_bulk_import_get_job_status_processing: true,
        user_bulk_import_get_job_status_success: false,
        user_bulk_import_get_job_status_data: [],
        user_bulk_import_get_job_status_failed: false,
        user_bulk_import_get_job_status_message: {},
      };

    case actionTypes.USER_BULK_IMPORT_GET_JOB_STATUS_SUCCESS: {
      return {
        ...state,
        user_bulk_import_get_job_status_processing: false,
        user_bulk_import_get_job_status_success: true,
        user_bulk_import_get_job_status_data: action.data,
        user_bulk_import_get_job_status_failed: false,
        user_bulk_import_get_job_status_message: {},
      };
    }
    case actionTypes.USER_BULK_IMPORT_GET_JOB_STATUS_FAILED:
      return {
        ...state,
        user_bulk_import_get_job_status_processing: false,
        user_bulk_import_get_job_status_success: false,
        user_bulk_import_get_job_status_data: [],
        user_bulk_import_get_job_status_failed: true,
        user_bulk_import_get_job_status_message: action.data,
      };

    // user bulk import logs
    case actionTypes.USER_BULK_IMPORT_LOGS_INITIAL:
      return {
        ...state,

        user_bulk_import_logs_processing: false,
        user_bulk_import_logs_success: false,
        user_bulk_import_logs_data: false,
        user_bulk_importlogs_failed: false,
        user_bulk_import_logs_message: {},
      };
    case actionTypes.USER_BULK_IMPORT_LOGS_PROCESS:
      return {
        ...state,
        user_bulk_import_logs_processing: true,
        user_bulk_import_logs_success: false,
        user_bulk_import_logs_data: false,
        user_bulk_importlogs_failed: false,
        user_bulk_import_logs_message: {},
      };

    case actionTypes.USER_BULK_IMPORT_LOGS_SUCCESS: {
      return {
        ...state,
        user_bulk_import_logs_processing: false,
        user_bulk_import_logs_success: true,
        user_bulk_import_logs_data: action.data,
        user_bulk_importlogs_failed: false,
        user_bulk_import_logs_message: {},
      };
    }
    case actionTypes.USER_BULK_IMPORT_LOGS_FAILED:
      return {
        ...state,
        user_bulk_import_logs_processing: false,
        user_bulk_import_logs_success: false,
        user_bulk_import_logs_data: false,
        user_bulk_importlogs_failed: false,
        user_bulk_import_logs_message: action.data,
      };

    case actionTypes.USER_NEW_LIST_INITIAL:
      return {
        ...state,
        user_new_list_processing: false,
        user_new_list_success: false,
        user_new_list_failed: false,
        user_new_list_data: [],
      };

    case actionTypes.USER_NEW_LIST_PROCESS:
      return {
        ...state,
        user_new_list_processing: true,
        user_new_list_success: false,
        user_new_list_failed: false,
        user_new_list_data: [],
      };

    case actionTypes.USER_NEW_LIST_SUCCESS:
      return {
        ...state,
        user_new_list_processing: false,
        user_new_list_success: true,
        user_new_list_failed: false,
        user_new_list_data: action.data,
      };
    case actionTypes.USER_NEW_LIST_FAILED:
      return {
        ...state,
        user_new_list_processing: false,
        user_new_list_success: false,
        user_new_list_failed: true,
        user_new_list_data: action.data,
      };

    case actionTypes.USER_SEARCH_LIST_INITIAL:
      return {
        ...state,
        user_search_list_processing: false,
        user_search_list_success: false,
        user_search_list_failed: false,
        user_search_list_data: { data: [], meta: {} },
        user_search_list_message: "",
      };

    case actionTypes.USER_SEARCH_LIST_PROCESS:
      return {
        ...state,
        user_search_list_processing: true,
        user_search_list_success: false,
        user_search_list_failed: false,
        user_search_list_data: { data: [], meta: {} },
        user_search_list_message: "",
      };

    case actionTypes.USER_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        user_search_list_processing: false,
        user_search_list_success: true,
        user_search_list_failed: false,
        user_search_list_data: action.data,
        user_search_list_message: "",
      };
    case actionTypes.USER_SEARCH_LIST_FAILED:
      return {
        ...state,
        user_search_list_processing: false,
        user_search_list_success: false,
        user_search_list_failed: true,
        user_search_list_data: { data: [], meta: {} },
        user_search_list_message: action.data,
      };

    case actionTypes.DEACTIVATE_USERS:
      return {
        ...state,
        deactivateUsersLoading: true,
        deactivateUsersSuccess: false,
      };

    case actionTypes.DEACTIVATE_USERS_COMPLETE: {
      const userMapData = { ...state.user_map_data };
      action.payload.deactivatedIdentityIDs.forEach((id) => {
        delete userMapData[id];
      });
      return {
        ...state,
        deactivateUsersLoading: false,
        deactivateUsersSuccess: action.payload.success,
        user_map_data: userMapData,
        user_list_data: getUserListData(userMapData),
        all_active_user_list_data: getAllActiveUserListData(userMapData),
      };
    }

    case actionTypes.ACTVATE_USERS:
      return {
        ...state,
        activateUsersLoading: true,
        activateUsersSuccess: false,
      };

    case actionTypes.ACTVATE_USERS_COMPLETE: {
      const userMapData = action.payload.userList.reduce(
        (acc, user) => ({
          ...acc,
          [user?.identity.id]: {
            ...user,
            ...user?.identity,
            identity: { ...user?.identity, is_active: true },
            is_active: true,
          },
        }),
        { ...state.user_map_data }
      );

      return {
        ...state,
        activateUsersLoading: false,
        activateUsersSuccess: action.payload.success,
        user_map_data: userMapData,
        user_list_data: getUserListData(userMapData),
        all_active_user_list_data: getAllActiveUserListData(userMapData),
      };
    }

    case actionTypes.EXPORT_USER_LIST_INITIAL:
      return {
        ...state,
        export_user_list_processing: false,
        export_user_list_success: false,
        export_user_list_failed: false,
        export_user_list_error_msg: "",
      };
    case actionTypes.EXPORT_USER_LIST_PROCESS:
      return {
        ...state,
        export_user_list_processing: true,
        export_user_list_success: false,
        export_user_list_failed: false,
        export_user_list_error_msg: "",
      };

    case actionTypes.EXPORT_USER_LIST_SUCCESS:
      return {
        ...state,
        export_user_list_processing: false,
        export_user_list_success: true,
        export_user_list_failed: false,
        export_user_list_error_msg: "",
      };
    case actionTypes.EXPORT_USER_LIST_FAILED:
      return {
        ...state,
        export_user_list_processing: false,
        export_user_list_success: false,
        export_user_list_failed: true,
        export_user_list_error_msg: action.data,
      };

    case actionTypes.FETCH_ME_INFO:
      return {
        ...state,
        medetails_loading: true,
        medetails_failed: false,
        medetails_error_message: "",
        medetails_recheck_processing: true,
        medetails_recheck_success: false,
        medetails_recheck_failed: false,
        medetails_recheck_data: {},
        me_role_loading: true,
        me_role_failed: false,
        me_role_error_message: "",
      };

    case actionTypes.FETCH_ME_ROLE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        me_role_loading: false,
        me_role_success: !!(action.payload && action.payload.length > 0),
        me_role_data:
          action.payload && action.payload.length > 0 ? action.payload[0] : {},
        me_role_error_message: "",
      };

    case actionTypes.FETCH_ME_ROLE_ASSIGNMENT_FAILED:
      return {
        ...state,
        me_role_loading: false,
        me_role_success: false,
        me_role_data: {},
        me_role_error_message: action.payload,
      };
    case actionTypes.BULK_USER_TEAM_UPDATE:
      return {
        ...state,
        userTeamUpdateLoading: true,
        userTeamUpdateErrorMessage: "",
        userTeamUpdateSuccess: false,
      };

    case actionTypes.BULK_USER_TEAM_UPDATE_SUCCESS: {
      return {
        ...state,
        userTeamUpdateLoading: false,
        // userTeamUpdateData: {
        //   ...state.manualtimeData,
        //   [action.payload.id]: action.payload,
        // },

        userTeamUpdateSuccess: true,
      };
    }

    case actionTypes.BULK_USER_TEAM_UPDATE_FAILED:
      return {
        ...state,
        userTeamUpdateLoading: false,
        userTeamUpdateErrorMessage: action.payload,
      };

    default:
      return state;
  }
};
